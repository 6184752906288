import { Injectable } from '@angular/core';
import { LDMultiKindContext, LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { Observable, take } from 'rxjs';
import { anonymousUser } from '../domain/anonymous-user.constant';
import { FeatureFlag } from '../domain/feature-flags.enum';
import { LaunchDarklyService } from '../feat-launch-darkly-service/launch-darkly.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureProviderService {
  private isIdentified = false;

  constructor(private readonly launchDarklyService: LaunchDarklyService) {}

  /**
   * @deprecated The method should not be used
   */
  public getFeature<T = boolean>(key: FeatureFlag, defaultValue: T): T {
    return this.launchDarklyService.getFeature(key, defaultValue);
  }

  public getFeature$<T = boolean>(key: FeatureFlag, defaultValue: T): Observable<T> {
    return this.getLiveFeature$(key, defaultValue).pipe(take(1));
  }

  public getLiveFeature$<T = boolean>(key: FeatureFlag, defaultValue: T): Observable<T> {
    return this.launchDarklyService.getLiveFeature$(key, defaultValue);
  }

  public async identify(user: LDSingleKindContext | LDMultiKindContext, isIdentified = true): Promise<void> {
    await this.launchDarklyService.identify(user);
    this.isIdentified = isIdentified;
  }

  public async unidentify(): Promise<void> {
    if (this.isIdentified) {
      await this.identify(anonymousUser, false);
    }
  }
}

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WA_WINDOW } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Inject(WA_WINDOW) private readonly window: Window,
  ) {}

  public get isPlatformBrowserBot() {
    return /bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(this.window?.navigator?.userAgent);
  }

  public get isPlatformServer() {
    return isPlatformServer(this.platformId);
  }

  public get isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  public get isTrackable() {
    return this.isPlatformBrowser && !this.isPlatformBrowserBot;
  }
}

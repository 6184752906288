import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { OldAuthInterceptor } from './util-auth-interceptor/auth.interceptor';
import { JsonHeaderInterceptor } from './util-json-header-intercepter/json-header.interceptor';
import { TokenProviderService } from './util-token-provider';

function initialiseTokenProvider(tokenProviderService: TokenProviderService) {
  return () => tokenProviderService.initialise();
}

export function provideRmaHttpClient(hasAuth = true): EnvironmentProviders {
  return hasAuth
    ? makeEnvironmentProviders([
        { provide: HTTP_INTERCEPTORS, useClass: OldAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true },
        provideAppInitializer(() => {
          const initializerFn = initialiseTokenProvider(inject(TokenProviderService));
          return initializerFn();
        }),
      ])
    : makeEnvironmentProviders([{ provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true }]);
}

import { Directive, effect, ElementRef, inject, input, Renderer2 } from '@angular/core';
import { MenuTheme, ThemeConfig } from './theme';

@Directive({
  selector: 'a[rmaMenuItem]',
  standalone: true,
})
export class MenuItemDirective {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  public readonly theme = input<MenuTheme>('primary');

  constructor() {
    effect(() => {
      const itemClasses = ThemeConfig[this.theme()];

      this.renderer.addClass(this.elementRef.nativeElement, 'no-underline');
      this.renderer.addClass(this.elementRef.nativeElement, itemClasses.itemColor);
      this.renderer.addClass(this.elementRef.nativeElement, `hover:${itemClasses.hoverItemColor}`);
    });
  }
}

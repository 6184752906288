import { IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { ImageOptimiseService } from '@rma/generic/util/pipes/image-optimiser';

const imageUrlFactory = (rmaUrls: RmaUrlsService, optimiseService: ImageOptimiseService) => {
  return (config: ImageLoaderConfig) => {
    // Handle placeholders
    const placeholders = ['placeholder/agency.jpg', 'placeholder/agent.jpg', 'placeholder/no-property-placeholder.png'];
    if (placeholders.includes(config.src)) {
      return rmaUrls.imageUrl(config.src);
    }

    if (config.loaderParams?.['type'] === 'staticImage') {
      if (config.width) {
        const index = config.src.lastIndexOf('.');
        const image = `${config.src.substring(0, index)}-w_${config.width}${config.src.substring(index, config.src.length)}`;

        return rmaUrls.imageUrl(image);
      } else {
        return rmaUrls.imageUrl(config.src);
      }
    }

    return optimiseService.getFromCdn(config.src, config.loaderParams?.['transformations']);
  };
};

export function provideCustomImageLoader() {
  return [
    {
      provide: IMAGE_LOADER,
      deps: [RmaUrlsService, ImageOptimiseService],
      useFactory: imageUrlFactory,
    },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [576, 640, 768, 1024, 1280, 1536],
      },
    },
  ];
}

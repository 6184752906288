<ng-container *transloco="let t; read: scope() + '.navigation'">
  @if (displayMode() === 'menu') {
    <button
      class="h-full text-blue-50 hover:opacity-80"
      type="button"
      aria-haspopup="true"
      aria-controls="menu"
      (click)="toggleSideNav.emit()">
      <span class="sr-only"> {{ t('menuTitle') }} </span>
      <rma-icon role="menu" glyph="menu" />
    </button>
  } @else {
    <div class="flex items-center gap-2 h-full">
      <a [href]="about()" class="text-steel-100 hover:text-purple-50 no-underline font-medium">{{ t('about') }}</a>
      <rma-menu mode="popover" [text]="t('solutions')" [items]="forMenuItems" />
    </div>
  }

  <ng-template #forMenuItems>
    <div class="flex flex-col gap-2 bg-white p-4 rounded-xl nc-glow-lg">
      <a [href]="group()" rmaMenuItem>{{ t('group') }}</a>
      <a [href]="agents()" rmaMenuItem>{{ t('agents') }}</a>
    </div>
  </ng-template>
</ng-container>

import { EnvironmentProviders, makeEnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { initialize } from 'launchdarkly-js-client-sdk';
import { LAUNCH_DARKLY_INITIALISE } from './domain/launch-darkly-initialise.const';
import { FeatureUserService } from './feat-feature-user/feature-user.service';
import { LaunchDarklyService } from './feat-launch-darkly-service/launch-darkly.service';

function initialiseLaunchDarkly(launchDarklyService: LaunchDarklyService): () => Promise<void> {
  return () => launchDarklyService.load();
}

function initialiseUserIdentification(featureUserService: FeatureUserService) {
  return () => featureUserService.initialise();
}

export function provideRmaLaunchDarklyBrowser(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: LAUNCH_DARKLY_INITIALISE,
      useValue: initialize,
    },
    provideAppInitializer(() => {
      const initializerFn = initialiseLaunchDarkly(inject(LaunchDarklyService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = initialiseUserIdentification(inject(FeatureUserService));
      return initializerFn();
    }),
  ]);
}

// Use these constants to reuse existing cloudinary sizes where possible.
// To reduce duplicated storage costs in cloudinary + increase reuse of existing cached images/sizes.

export const ImageResizeCampaignThumbnail = 'q_auto:good,f_auto,w_120,h_80,c_fill,g_auto';

// Using c_pad to ensure the entire floor plan is visible using 1DPR
export const FloorPlanGallery = 'q_auto:eco,f_auto,w_423,h_240,c_pad';
export const FloorPlanModal = 'q_auto:eco,f_auto,w_768,h_1024,c_pad';

export const FeaturedReviewTitle = 'q_auto:eco,f_auto,h_120,c_limit/w_340,c_crop';

export const AvatarStandard = 'q_auto:best,f_auto,w_200,h_200,c_limit,c_pad';
export const AvatarLandscape = 'q_auto:best,f_auto,w_288,h_216,c_crop,c_fill,g_face';

export const ProfileBanner = {
  Logo: 'q_auto:good,f_auto,h_80,c_limit',
};

/**
 * content (short)|(long)|(forever)
 */
export type ImageCacheType = 'cs' | 'cl' | 'cf';

export const RmaImageCacheSize = {
  RmaImageCacheSizeAvatar: 'q_auto:eco,f_auto,w_200,h_200,c_limit,c_pad',
  RmaImageCacheSizeTile: 'q_auto:eco,f_auto,w_340,h_255',
  RmaImageCacheSizeDefaultMobile: 'q_auto:eco,f_auto,w_530,h_400,c_fill,g_center,fl_progressive',
  RmaImageCacheSizeDefaultDesktop: 'q_auto:eco,f_auto,w_900,h_600,c_limit',
} as const;

// eslint-disable-next-line @typescript-eslint/ban-types
export type RmaImageSize = keyof typeof RmaImageCacheSize | (string & {});

export function isRmaImageCacheSize(params: RmaImageSize): params is RmaImageSize {
  return Object.keys(RmaImageCacheSize).includes(params);
}

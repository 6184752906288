import { inject, Injectable } from '@angular/core';
import { CurrentUserService } from '@rma/accounts/user';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { FeatureFlag, FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { IntercomService } from '@rma/generic/util/tracking/intercom';
import { CurrentSubscriptionService } from '@rma/subscriptions/public-api/da-subscriptions';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { AircallPhoneNumbers } from '../domain/aircall.model';
import { FooterLink, SocialLink } from '../domain/footer-links.model';
import { SiteLinkGroup } from '../domain/site-link-group.model';
import { LayoutService, LocationFooterLink } from './footer-api.service';
import { excludedLocations } from './footer.const';

export interface FooterContactDetails {
  salesNumber: string | null;
  supportNumber: string | null;
  supportEmail: string | null;
  trainingWebinarUrl: string | null;
  intercom: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FooterFacade {
  private readonly layoutService = inject(LayoutService);
  private readonly config = inject(Environment);
  private readonly featureProviderService = inject(FeatureProviderService);
  private readonly currentSubscriptionService = inject(CurrentSubscriptionService);
  private readonly intercomService = inject(IntercomService);
  private readonly userService = inject(CurrentUserService);
  private readonly platformService = inject(PlatformService);

  public readonly hasFinance$ = this.featureProviderService.getFeature$(FeatureFlags.EnableFinance, false);
  public readonly hasLeasing$ = this.featureProviderService.getFeature$(FeatureFlags.EnableLeasing, false);
  public readonly awardsUrl$ = this.featureProviderService.getFeature$(FeatureFlags.FooterAwardsUrl, null);

  public readonly locationLinks$ = this.getLocationLinks();
  public readonly socialLinks = this.getSocialLinks();

  public getLocationLinks(): Observable<LocationFooterLink[]> {
    return this.layoutService
      .getLocationFooterLinks()
      .pipe(map((x) => x.filter((location) => !excludedLocations.includes(location.name)).sort((a, b) => a.name.localeCompare(b.name))));
  }

  public getContactDetails(): Observable<FooterContactDetails> {
    const displayEmailSupport$ = this.getShowForLoggedInOrFeatureFlag(FeatureFlags.FooterShowSupportEmailToUnsubscribedUsers);
    const displayPhoneSupport$ = this.getShowForLoggedInOrFeatureFlag(FeatureFlags.FooterShowSupportNumberToUnsubscribedUsers);
    const supportEmail$ = this.featureProviderService.getFeature$<string | null>(FeatureFlags.SupportEmail, null);
    const phoneNumbers$ = this.featureProviderService.getFeature$<AircallPhoneNumbers | null>(FeatureFlags.AircallPhoneNumbers, null);
    const trainingWebinarUrl$ = this.featureProviderService.getFeature$<string | null>(FeatureFlags.TrainingWebinarUrl, null);

    return combineLatest({
      salesNumber: phoneNumbers$.pipe(map((x) => x?.Sales ?? null)),
      supportNumber: combineLatest([phoneNumbers$, displayPhoneSupport$]).pipe(
        map(([phoneNos, display]) => (display ? (phoneNos?.Support ?? null) : null)),
      ),
      supportEmail: combineLatest([supportEmail$, displayEmailSupport$]).pipe(map(([email, display]) => (display ? email : null))),
      trainingWebinarUrl: combineLatest([trainingWebinarUrl$, this.currentSubscriptionService.currentSubscription$]).pipe(
        map(([url, currentSub]) => (!(currentSub?.isFree ?? true) ? url : null)),
      ),
      intercom: this.intercomService.isLoaded$.pipe(startWith(false)),
    });
  }

  public getCommunitySiteLinks(): Observable<FooterLink[]> {
    const awardsPage$ = this.featureProviderService.getFeature$(FeatureFlags.FooterAwardsUrl, null);

    return combineLatest({
      awardsLink: awardsPage$.pipe(map((x) => x ?? null)),
      trustLink: of('/trust'),
      leaveReviewLink: of('/review'),
    }).pipe(
      map(({ awardsLink, trustLink, leaveReviewLink }) => {
        const links: FooterLink[] = [
          // { label: { key: 'trust' }, link: trustLink },
          // { label: { key: 'leaveReview' }, link: leaveReviewLink },
        ];
        if (awardsLink) {
          links.push({ label: { key: 'awards' }, link: awardsLink, href: true });
        }

        return links;
      }),
    );
  }

  public getLegalLinks(): Observable<FooterLink[]> {
    return this.featureProviderService.getFeature$(FeatureFlags.EnableMlsFooterLink, false).pipe(
      map((enableMlsFooterLink: boolean) => {
        const list: FooterLink[] = [
          {
            label: { key: 'privacy' },
            link: '/privacy-policy',
          },
          {
            label: { key: 'terms' },
            link: '/terms-and-conditions',
          },
          {
            label: { key: 'licensing' },
            link: '/mls/licensing',
          },
          {
            label: { key: 'sitemap' },
            link: '/sitemap',
          },
        ];
        return enableMlsFooterLink ? list : list.filter((x) => x.link !== '/mls/licensing');
      }),
    );
  }

  public getSiteLinks(): Observable<SiteLinkGroup[]> {
    return combineLatest([this.getCompanyLinks(), this.getHelpLinks(), this.getProductLinks(), this.getCommunitySiteLinks()]).pipe(
      map(([companyLinks, helpLinks, productLinks, communityLinks]) => [
        {
          title: 'company',
          links: companyLinks,
        },
        {
          title: 'help',
          links: helpLinks,
        },
        {
          title: 'product',
          links: productLinks,
        },
        {
          title: 'community',
          links: communityLinks,
        },
      ]),
    );
  }

  private getShowForLoggedInOrFeatureFlag(flag: FeatureFlag) {
    const showSupportNumberToUnsubscribedUsers$ = this.featureProviderService.getFeature$(flag, true);
    return combineLatest([showSupportNumberToUnsubscribedUsers$, this.currentSubscriptionService.currentSubscription$]).pipe(
      map(([showForFree, currentSub]) => showForFree || !(currentSub?.isFree ?? true)),
    );
  }

  private getSocialLinks(): SocialLink[] {
    const { facebookUrl, twitterUrl, linkedinUrl, instagramUrl } = this.config.social;

    const list: SocialLink[] = [
      {
        label: 'Facebook',
        icon: 'social-facebook',
        href: facebookUrl,
      },
      {
        label: 'Instagram',
        icon: 'social-instagram',
        href: instagramUrl,
      },
      {
        label: 'LinkedIn',
        icon: 'social-linkedin',
        href: linkedinUrl,
      },
      {
        label: 'Twitter',
        icon: 'social-twitter',
        href: twitterUrl,
      },
    ];
    return list.filter((x) => x.href);
  }

  private getShowForUnAuthenticatedUser() {
    const isAuthenticated$ = this.userService.isAuthenticated$.pipe(
      filter(() => this.platformService.isPlatformBrowser || this.platformService.isPlatformBrowserBot),
    );
    return isAuthenticated$;
  }

  private getCompanyLinks(): Observable<FooterLink[]> {
    const links: FooterLink[] = [
      {
        label: { key: 'about' },
        link: 'https://go.ratemyagent.com.au/about-us',
        href: true,
      },
      {
        label: { key: 'currentJobs' },
        link: 'https://ratemyagent.bamboohr.com/careers',
        href: true,
      },
      {
        label: { key: 'brandGuidelines' },
        link: '/brand',
        href: true,
      },
      {
        label: { key: 'faq' },
        link: '/faq',
      },
    ];
    return of(links);
  }

  private getHelpLinks(): Observable<FooterLink[]> {
    return this.featureProviderService.getFeature$(FeatureFlags.SupportCentreUrl, '').pipe(
      map((supportUrl) => {
        const links: FooterLink[] = [
          // { label: { key: 'grow' }, link: '/', href: true },
          { label: { key: 'tools' }, link: '/blog/category/tools-tips' },
          // { label: { key: 'gettingStarted' }, link: '/', href: true },
          { label: { key: 'help' }, link: supportUrl, href: true },
        ];
        return links;
      }),
    );
  }

  private getProductLinks(): Observable<FooterLink[]> {
    return combineLatest([
      this.getShowForUnAuthenticatedUser(),
      this.featureProviderService.getFeature$(FeatureFlags.EnableLeasing, false),
      this.featureProviderService.getFeature$(FeatureFlags.EnableFinance, false),
    ]).pipe(
      map(([authenticated, hasLeasing, hasFinance]) => {
        const links: FooterLink[] = [
          { label: { key: 'compare' }, link: '/account/compare-agents' },
          { label: { key: 'tracked' }, link: '/tracked-properties' },
        ];
        if (hasFinance) {
          links.push({ label: { key: 'finance' }, link: '/search', queryParams: { browseMode: 'finance' } });
        }
        links.push({ label: { key: 'sales' }, link: '/search', queryParams: { browseMode: 'sales' } });
        if (hasLeasing) {
          links.push({ label: { key: 'rentals' }, link: '/search', queryParams: { browseMode: 'leasing' } });
        }
        links.push({ label: { key: 'plans' }, link: '/subscriptions' });
        if (!authenticated) {
          links.push({ label: { key: 'login' }, link: '/profile/login' }, { label: { key: 'join' }, link: '/account/join' });
        }
        return links;
      }),
    );
  }
}

import { Inject, Injectable, Optional } from '@angular/core';
import { WA_WINDOW } from '@ng-web-apis/common';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { TrackableUser } from '../domain';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(
    @Optional() @Inject(WA_WINDOW) private readonly window: { dataLayer: unknown[] },
    private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  ) {}

  trackUser(user: TrackableUser) {
    if (!this.window) {
      return;
    }

    if (user.userId) {
      this.angulartics2GoogleTagManager.setUsername(user.userId);
    }

    const dataLayer = (this.window.dataLayer = this.window.dataLayer || []);
    dataLayer.push({ userType: user.userType, userId: user.userId });
  }
}

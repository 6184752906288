import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
} from '@angular/core';
import { MAT_PREFIX, MAT_SUFFIX, MatPrefix, MatSuffix } from '@angular/material/form-field';
// eslint-disable-next-line no-restricted-imports
import { MatIconModule } from '@angular/material/icon';
import { MaterialIcons } from '../domain/mat-icons.const';
import { CustomIcons, rmaIcons } from '../domain/rma-icons.const';
export type RmaIcons = CustomIcons | MaterialIcons;

const ICON_CONFIG = {
  extraSmall: {
    size: 12,
    padding: 4,
  },
  small: {
    size: 18,
    padding: 8,
  },
  normal: {
    size: 24,
    padding: 8,
  },
  large: {
    size: 32,
    padding: 8,
  },
  orb: {
    size: 40,
    padding: 12,
  },
} as const;

export type IconSize = keyof typeof ICON_CONFIG;
export type IconTheme = 'primary' | 'primary-alt' | 'discovery' | 'caution' | 'inactive' | 'success' | 'star';

@Component({
  selector: 'rma-icon',
  imports: [MatIconModule],
  templateUrl: './ui-icon.component.html',
  styleUrls: ['./ui-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmaIconComponent implements OnChanges {
  @Input({ required: true })
  public glyph: RmaIcons = 'done';

  @Input()
  public fill: 0 | 1 = 0;

  @Input()
  public inline = false;

  @HostBinding('class')
  @Input()
  public theme?: IconTheme;

  @HostBinding('class.icon-rounded')
  @Input()
  public rounded = false;

  @Input()
  public size: IconSize = 'normal';
  /**
   * @deprecated: This was to aid migration to new icon component and should not be used for new work
   */
  @Input()
  public legacySize?: string;

  @HostBinding('class.form-field-padding')
  protected formFieldPadding = false;

  @HostBinding('class.icon-button-padding-left')
  protected iconButtonPaddingLeft = false;

  @HostBinding('class.icon-button-padding-right')
  protected iconButtonPaddingRight = false;

  protected readonly fontSet = 'material-symbols-outlined';
  protected iconType: 'material' | 'custom' = 'material';

  public constructor(
    public readonly elRef: ElementRef,
    @Optional() @Inject(MAT_PREFIX) readonly matPrefix: MatPrefix,
    @Optional() @Inject(MAT_SUFFIX) readonly matSuffix: MatSuffix,
  ) {
    this.setFill();
    this.setIconSize();
    this.setPadding();
    this.setButtonPadding();

    if (this.matPrefix || this.matSuffix) {
      this.formFieldPadding = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['glyph'] && this.glyph) {
      this.iconType = rmaIcons.some(({ name }) => name === this.glyph) ? 'custom' : 'material';
    }

    if (changes['fill']) {
      this.setFill();
    }

    if (changes['size'] && this.size) {
      this.setIconSize();
      this.setPadding();
    }

    //This should be removed when all instances of legacySize have been removed
    if (changes['legacySize'] && this.legacySize) {
      this.elRef.nativeElement.style.setProperty('--rma-icon-size', this.legacySize);
    }
  }

  private setFill() {
    this.elRef.nativeElement.style.setProperty('--rma-icon-fill', this.fill);
  }

  private setIconSize() {
    const iconSize = `${ICON_CONFIG[this.size ?? 'normal'].size}px`;
    this.elRef.nativeElement.style.setProperty('--rma-icon-size', iconSize);
  }

  private setPadding() {
    const paddingSize = `${ICON_CONFIG[this.size ?? 'normal'].padding}px`;
    this.elRef.nativeElement.style.setProperty('--rma-padding-size', paddingSize);
  }

  private setButtonPadding() {
    const iconPositionEnd = this.hasAttribute('iconPositionEnd');

    if (iconPositionEnd) {
      this.iconButtonPaddingLeft = true;
    }

    if (this.hasAttribute('matButtonIcon') && !iconPositionEnd) {
      this.iconButtonPaddingRight = true;
    }
  }

  private hasAttribute(attribute: string) {
    return this.elRef.nativeElement.getAttribute(attribute) !== null;
  }
}

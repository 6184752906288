import { Inject, Injectable } from '@angular/core';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';
import { StorageTypes, UserTrackProperty } from '@rma/campaigns/private/domain-track-campaign';

@Injectable({ providedIn: 'root' })
export class LocalTrackPropertyStorageService {
  public readonly storageType = StorageTypes.Local;
  private readonly key = 'Users.TrackedProperties';

  constructor(@Inject(WA_LOCAL_STORAGE) private localStorage: Storage) {}

  public add(campaignCode: string, trackId: string): void {
    const trackedProperties = this.getFromLocalStorage<UserTrackProperty[]>(this.key);
    if (!trackedProperties) {
      // no user track property information found in local storage
      this.setToLocalStorage<UserTrackProperty[]>(this.key, [{ campaignCode, trackId }]);
    }
    // add only new unique agent codes to local storage
    else if (trackedProperties.findIndex((x) => x.trackId === trackId) === -1) {
      this.setToLocalStorage<UserTrackProperty[]>(this.key, [...trackedProperties, { campaignCode, trackId }]);
    }
  }

  public remove(trackId: string): void {
    const trackedProperties = this.getFromLocalStorage<UserTrackProperty[]>(this.key);
    if (trackedProperties) {
      this.setToLocalStorage<UserTrackProperty[]>(
        this.key,
        trackedProperties.filter((property) => property.trackId !== trackId),
      );
    }
  }

  public getTrackId(campaignCode: string): string | null {
    const trackedProperties = this.getFromLocalStorage<UserTrackProperty[]>(this.key);
    const trackedProperty = trackedProperties?.find((x) => x.campaignCode === campaignCode);
    return trackedProperty ? trackedProperty.trackId : null;
  }

  public getTrackedPropertyCodes(): string[] {
    const trackedProperties = this.getFromLocalStorage<UserTrackProperty[]>(this.key);
    return trackedProperties ? trackedProperties.map((x) => x.campaignCode) : [];
  }

  public getFromLocalStorage<T>(key: string): T | null {
    const value = this.localStorage?.getItem(key);
    return value ? (JSON.parse(value) ?? null) : null;
  }

  public setToLocalStorage<T>(key: string, data: T) {
    if (data) {
      this.localStorage?.setItem(key, JSON.stringify(data));
    }
  }
}

import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { IS_PRODUCTION } from '@rma/generic/util/environment';
import { installTrackJS } from '@rma/generic/util/tracking/track-js';
import { AppComponent } from './app/app.component';
import { config } from './app/app.config';

if (IS_PRODUCTION) {
  enableProdMode();
  installTrackJS('landing-pages');
}

document.addEventListener('DOMContentLoaded', () => bootstrapApplication(AppComponent, config).catch((err) => console.error(err)));

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { LocationFooterLink } from '../data-access/footer-api.service';
import { FOOTER_TRANSLATION_SCOPE } from '../domain/translation-scopes';
import { FooterLocationLinksComponent } from '../ui-footer-location-links/footer-location-links.component';

@Component({
  selector: 'rma-footer-quick-search',
  imports: [FooterLocationLinksComponent, TranslocoModule],
  templateUrl: './quick-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope(FOOTER_TRANSLATION_SCOPE)],
  host: {
    class:
      'bg-steel-90 text-steel-20 text-left pb-16 md:pb-6 px-6 py-8 sm:px-8 mb-12 md:mb-0 md:text-center lg:text-left rounded-3xl flex flex-col md:flex-row justify-between gap-8 md:gap-20',
  },
})
export class QuickSearchComponent implements OnChanges {
  @Input({ required: true })
  public links: LocationFooterLink[] | null = null;

  @Input({ required: true })
  public hasFinance: boolean | null = null;

  @Input({ required: true })
  public hasLeasing: boolean | null = null;

  protected readonly scope = `${FOOTER_TRANSLATION_SCOPE}.siteLinks`;

  protected isMultiColumn = false;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['hasLeasing'] || changes['hasFinance']) {
      this.isMultiColumn = !(this.hasFinance && this.hasLeasing);
    }
  }
}

import { EnvironmentProviders, makeEnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { CustomIconService } from './custom-icons.service';

export function provideRmaIcon(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideAppInitializer(() => {
      const initializerFn = (
        (iconService: CustomIconService) => () =>
          iconService.register()
      )(inject(CustomIconService));
      return initializerFn();
    }),
  ]);
}

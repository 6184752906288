// Only Perm Features (Should be marked as a permanent flag in LaunchDarkly)
export const FeatureFlags = {
  AboutPage: 'Web.AboutPage',
  AdditionalBlogUrls: 'Web.AdditionalBlogUrls',
  AgentLanderUrl: 'Web.AgentLanderUrl',
  AgentLandingPartnerships: 'Web.AgentLandingPartnerships',
  AircallPhoneNumbers: 'Web.AircallPhoneNumbers',
  AwardsConfig: 'Web.AwardsConfig',
  AwardsCurrentYear: 'Awards.CurrentYear',
  BrandFooterLink: 'Web.FooterBrandUrl',
  ContactSalesPhoneNumber: 'Web.ContactSalesPhoneNumber',
  ContactUsUrl: 'Web.ContactUsUrl',
  CorporateRatesRequestFormUrl: 'Web.CorporateRatesRequestFormUrl',
  DatasourceDiscalimer: 'Web.DatasourceDisclaimer',
  DisableCloudinary: 'Web.DisableCloudinary',
  EnableAwards: 'Core.EnableAwards',
  EnableBuyerAgents: 'Core.EnableBuyerAgents',
  EnableCustomAds: 'Web.EnableCustomAds',
  EnableFinance: 'Core.EnableFinance',
  EnableFreeTrialCta: 'Public.EnableFreeTrialCta',
  EnableHomepageAgentCta: 'EnableHomepageAgentCta',
  EnableHomepageCaseStudies: 'EnableHomepageCaseStudies',
  EnableImageCaching: 'Web.EnableImageCache',
  EnableIntercom: 'Web.EnableIntercomLiveChat',
  EnableLeadAgentDisclaimer: 'Web.EnableLeadAgentDisclaimer',
  EnableLeasing: 'Web.EnableLeasing',
  EnableListingAttribution: 'Web.EnableListingAttribution',
  EnableMlsFooterLink: 'Web.ShowMlsLicensingFooterLink',
  EnableMobileNumberForMarketReport: 'Core.EnableMobileNumberForMarketReport',
  EnableNewOfficeSubscriptions: 'Web.EnableNewOfficeSubscriptions',
  EnablePromoter: 'Web.EnablePromoter',
  EnableSettlementPeriod: 'Core.EnableSettlementPeriod',
  EnableTeams: 'Core.EnableTeams',
  EnableUnrequestedReviews: 'Core.EnableUnrequestedReviews',
  FaqFooterLink: 'Web.FooterFaqUrl',
  FeaturedVideoReviews: 'Web.FeaturedVideoReviews',
  FindMyAgentPopupBanner: 'FindMyAgent.PopupBanner',
  FindMyAgentPropertyTypes: 'FindMyAgent.PropertyTypes',
  FindMyAgentSearchBanner: 'FindMyAgent.SearchBanner',
  FloatingHeads: 'Web.ConsumerHomeFloatingHeads',
  FooterAwardsUrl: 'Web.FooterAwardsUrl',
  FooterShowSupportEmailToUnsubscribedUsers: 'Web.FooterShowSupportEmailToUnsubscribedUsers',
  FooterShowSupportNumberToUnsubscribedUsers: 'Web.FooterShowSupportNumberToUnsubscribedUsers',
  HasMandatoryLicenceField: 'HasMandatoryLicenceField',
  HomePageBrokerageLink: 'Web.HomePageBrokerageLink',
  HomepageSearchImage: 'Web.HomepageSearchImage',
  MarketingBaseUrl: 'Web.MarketingBaseUrl',
  PromoteUnverified: 'Promoter.PromoteUnverified',
  PropertyTypeGrouping: 'Web.PropertyTypeGrouping',
  ShowReviewsOnListingLandingPages: 'LandingPages.Listing.ShowReviews',
  SubscriptionTierChannels: 'Web.SubscriptionPlanIntegrations',
  SupportCentreUrl: 'Core.SupportCentreUrl',
  SupportEmail: 'Web.SupportEmail',
  TeamsBillingGrouping: 'Web.TeamsBillingGrouping',
  TrainingWebinarUrl: 'TrainingWebinarUrl',
  TypeformSurvey2023Snippet: 'Web.TypeformSurvey2023Snippet',
} as const;

// Temporary Features
export const TempFeatureFlags = {
  EnableBrokerageProInterestCheck: 'Temp.EnableBrokerageProInterestCheck',
  HideDom: 'Temp.Public.HideDOM2023',
  OfficeSubscriptionContactRequest: 'Web.OfficeSubscriptionContactRequest',
  SpanishReviews: 'Temp.EnableSpanishReviewFlow',
  ProfileReviewAttributes: 'Temp.ProfileReviewAttributes',
  CreateProfileTrackCountryIndustryRole: 'Temp.CreateProfileTrackCountryIndustryRole',
} as const;

// Experiments Features
export const ExperimentFlags = {
  NlpSearch: 'Experiment.NlpSearch',
} as const;

type PermanentFlag = (typeof FeatureFlags)[keyof typeof FeatureFlags];
type TemporaryFlag = (typeof TempFeatureFlags)[keyof typeof TempFeatureFlags];
export type ExperimentFlag = (typeof ExperimentFlags)[keyof typeof ExperimentFlags];

export type FeatureFlag = PermanentFlag | TemporaryFlag | ExperimentFlag;

import { inject, Injectable } from '@angular/core';
import { CookiesService } from '@ngx-utils/cookies';

/**
 * A lot of this is due to the trixel cookie being set as HTTP only
 * It is no longer, but we need to cater for that.
 * Trixel cookies last a year, so we should be able to ignore this after 01/01/2026
 */
@Injectable()
export class DeviceIdentificationService {
  private readonly cookiesService = inject(CookiesService);

  private readonly trixelsCookie = 'DeviceId';
  private readonly fallBackCookie = 'FallbackDeviceId';

  private deviceId?: string;

  public getDeviceId(): string {
    if (this.deviceId) {
      return this.deviceId;
    }
    const cookieId = this.readFromCookie();
    if (cookieId) {
      this.deviceId = cookieId;
      return this.deviceId;
    }

    const uuid = this.generateUuid();

    this.cookiesService.put(this.fallBackCookie, uuid);
    this.deviceId = uuid;

    return this.deviceId;
  }
  private readFromCookie(): string | undefined {
    return this.cookiesService.get(this.trixelsCookie) ?? this.cookiesService.get(this.fallBackCookie);
  }

  private generateUuid(): string {
    return crypto.randomUUID ? crypto.randomUUID() : this.fallbackGenerateUuid();
  }

  /**
   * crypto.randomUUID has 95% support
   */
  private fallbackGenerateUuid(): string {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      // eslint-disable-next-line no-bitwise
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    );
  }
}

import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import { MenuComponent, MenuItemDirective } from '@rma/site/private/ui-menu';

type DisplayType = 'menu' | 'inline';

@Component({
  selector: 'rma-site-header-navigation',
  imports: [MenuComponent, MenuItemDirective, RmaIconComponent, TranslocoDirective],
  templateUrl: './navigation.component.html',
  host: {
    '[class]': 'height()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHeaderNavigationComponent {
  readonly scope = input.required<string>();
  readonly displayMode = input.required<DisplayType>();
  readonly about = input<string>();
  readonly agents = input<string>();
  readonly group = input<string>();

  readonly toggleSideNav = output();

  readonly height = computed(() => (this.displayMode() === 'menu' ? 'h-6' : 'h-auto'));
}

<button
  class="flex items-center gap-2 h-full font-medium pl-2 rounded-3xl"
  [class]="buttonTheme()"
  type="button"
  aria-haspopup="true"
  aria-controls="menu"
  (click)="onExpandCollapse()"
  (mouseover)="onHover()">
  <span>{{ text() }}</span>
  <rma-icon role="menu" glyph="expand_more" [@indicatorRotate]="indicator()" />
</button>

@if (mode() === 'inline' && indicator() === 'expanded') {
  <ng-content />
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { StaticImageUrlPipe } from '@rma/generic/util/environment';

@Component({
  selector: 'rma-site-header-logo',
  imports: [RouterLink, StaticImageUrlPipe],
  templateUrl: './logo.component.html',
  host: {
    class: 'mt-2', // to deal with center alignment with the speech bubble tick
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHeaderLogoComponent {}

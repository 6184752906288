export type MenuTheme = 'primary' | 'highlightBlue';

interface ThemePalette {
  buttonColor: string;
  buttonHoverColor: string;
  buttonHoverBackground: string;
  itemColor: string;
  hoverItemColor: string;
}

export const ThemeConfig: Record<MenuTheme, ThemePalette> = {
  primary: {
    buttonColor: 'text-steel-100',
    buttonHoverColor: 'text-purple-50',
    buttonHoverBackground: 'bg-white',
    itemColor: 'text-steel-100',
    hoverItemColor: 'text-purple-50',
  },
  highlightBlue: {
    buttonColor: 'text-highlight-blue',
    buttonHoverColor: 'text-blue-50',
    buttonHoverBackground: 'bg-blue-10',
    itemColor: 'text-white',
    hoverItemColor: 'text-highlight-blue',
  },
};
